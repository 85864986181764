.seperator-bg {
  position: absolute;
  bottom: 0;
  height: 30px;
  width: 100%;
  background-image: repeating-linear-gradient(
    90deg,
    #f1592a 0 20px,
    #1dc0e5 20px 40px
  ) !important;
}

/**
repeating-linear-gradient(
    90deg,
    #f1592a 0 20px,
    #1dc0e5 20px 40px
  ) !important;
  */
