.services-section {
  position: relative;
  height: 550px;
  width: 100%;
  background-color: #d8efff;
  clear: both;
  display: block;
  overflow: hidden;
  margin-bottom: 0;
}

.three-cards-container {
  display: flex;
  width: 95%;
  margin: 0 2.5%;
}

.section-title-services {
  text-align: center;
  vertical-align: middle;
  color: #008fc9;
  font-family: "Poppins", sans-serif;
  font-size: 65px;
  padding-top: 30px;
  margin: 0;
  font-weight: 400;
}

.slider-button-style {
  width: 80px;
  height: 80px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(56, 56, 56, 0.618);
  border-radius: 50%;
}

.icon-font.MuiSvgIcon-root {
  font-size: 400%;
  color: white;
}

.three-cards-container {
  justify-content: space-around;
  display: flex;
  width: 100%;
  margin: 0 auto;
}

.two-cards-container {
  justify-content: space-around;
  display: flex;
  width: 100%;
  margin: 0 auto;
}

.leftButton {
  width: 50px;
  height: 60px;
  background-color: rgba(90, 90, 90, 0.871);
  position: absolute;
  left: 0px;
  z-index: 100;
  border: none;
  border-radius: 50%;
}

.rightButton {
  width: 50px;
  height: 60px;
  background-color: rgba(90, 90, 90, 0.871);
  position: absolute;
  right: 0px;
  z-index: 100;
  border: none;
  border-radius: 50%;
}
