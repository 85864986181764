.home-section {
  margin-top: 60px;
  height: 600px;
  width: 100%;
  background-color: #008fc9;
  position: relative;
  text-align: center;
  /* border-bottom: 3px solid rgb(206, 206, 206); */
  clear: both;
  overflow: hidden;
  display: block;
}

/**
#seperator-home {
  position: absolute;
  bottom: 0;
  height: 30px;
  width: 100%;
  background-image: repeating-linear-gradient(
      90deg,
      #f1592a 0 20px,
      #1dc0e5 20px 40px
    ),
    -webkit-repeating-linear-gradient(90deg, #f1592a 0 20px, #1dc0e5 20px 40px);
}
*/
.home-button {
  position: absolute;
  width: 20%;
  height: 50px;
  background-color: white;
  border-radius: 5px;
  box-shadow: 4px -2px 8px -1px rgba(255, 255, 255, 0.52);
  -webkit-box-shadow: 4px -2px 7px -1px rgba(69, 69, 69, 0.52);
  -moz-box-shadow: 4px -2px 7px -1px rgba(69, 69, 69, 0.52);
  top: 65%;
  right: 50%;
  transform: translate(50%, 0%);
  cursor: pointer;
  text-decoration: none;
  z-index: 100;
}

.home-button-text {
  font-family: "Poppins", sans-serif;
  text-align: center;
  font-size: 20px;
  font-weight: 600;
  color: #f1592a;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, 0%);
}

.home-button:hover {
  width: 21%;
  height: 55px;
}

.home-desc {
  font-family: "Poppins", sans-serif;
  text-align: center;
  font-size: 25px;
  font-weight: 400;
  position: absolute;
  width: 100%;
  top: 45%;
  right: 50%;
  transform: translate(50%, 0%);
  color: white;
  width: 50%;
  z-index: 100;
}

.home-title {
  position: absolute;
  text-align: center;
  top: 9%;
  right: -50%;
  transform: translate(-50%, -50%);
  font-family: "Poppins", sans-serif;
  width: 100%;
  font-weight: 600;
  color: white;
}
/**
.contents h1:nth-child(1) {
  color: transparent;
  -webkit-text-stroke: 2px white;
}

.contents h1:nth-child(2) {
  color: white;

  animation: animated 4s ease-in-out infinite;
}
*/
@keyframes animated {
  0%,
  100% {
    clip-path: polygon(
      0 40%,
      15% 42%,
      24% 46%,
      36% 55%,
      46% 63%,
      60% 68%,
      76% 68%,
      91% 65%,
      100% 58%,
      100% 100%,
      0% 99%
    );
  }
  50% {
    clip-path: polygon(
      0 68%,
      11% 73%,
      29% 77%,
      44% 78%,
      57% 72%,
      67% 61%,
      79% 51%,
      90% 51%,
      100% 58%,
      100% 100%,
      0% 99%
    );
  }
}

/*********** WAVES BACKGROUND **************/

.ocean {
  height: 5%;
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  background: #015871;
}
/**https://s3-us-west-2.amazonaws.com/s.cdpn.io/85486/wave.svg*/
.wave-bg {
  background: url(https://s3-us-west-2.amazonaws.com/s.cdpn.io/85486/wave.svg)
    repeat-x;
  position: absolute;
  top: -198px;
  width: 6400px;
  height: 198px;
  animation: wave 7s cubic-bezier(0.36, 0.45, 0.63, 0.53) infinite;
  transform: translate3d(0, 0, 0);
}

.wave-bg:nth-of-type(2) {
  top: -175px;
  animation: wave 7s cubic-bezier(0.36, 0.45, 0.63, 0.53) -0.125s infinite,
    swell 7s ease -1.25s infinite;
  opacity: 1;
}

@keyframes wave {
  0% {
    margin-left: 0;
  }
  100% {
    margin-left: -1600px;
  }
}

@keyframes swell {
  0%,
  100% {
    transform: translate3d(0, -25px, 0);
  }
  50% {
    transform: translate3d(0, 5px, 0);
  }
}

@media only screen and (max-width: 1000px) {
  .home-desc {
    font-size: 18px;
  }
  .home-title {
    font-size: 110px;
    top: 22%;
  }

  .home-button-text {
    font-size: 16px;
  }
  .home-button {
    width: 20%;
    height: 50px;
  }
}

@media only screen and (max-width: 800px) {
  .home-title {
    font-size: 90px;
  }

  .home-button-text {
    font-size: 13px;
  }

  .home-button {
    width: 30%;
    height: 40px;
  }
  .home-desc {
    width: 80%;
  }
}

@media only screen and (max-width: 650px) {
  .home-title {
    font-size: 70px;
  }

  .home-desc {
    font-size: 16px;
  }

  .home-section {
    height: 500px;
  }

  .home-button {
    width: 40%;
  }
}

@media only screen and (max-width: 500px) {
  .home-title {
    font-size: 50px;
  }

  .home-desc {
    font-size: 16px;
  }

  .home-section {
    height: 400px;
  }
}

@media only screen and (max-width: 400px) {
  .home-button {
    width: 50%;
  }
}

@media only screen and (max-width: 350px) {
  .home-title {
    font-size: 42px;
  }

  .home-desc {
    font-size: 13px;
  }
}
