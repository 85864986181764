.blog-box-container {
  height: 120px;
  width: 30%;
  text-decoration: none;
  margin: 0 1.5%;
  color: white;
  background-color: #008fc9;
  position: relative;
  border-radius: 5px;
  box-shadow: 4px -2px 8px -1px rgba(255, 255, 255, 0.52);
  -webkit-box-shadow: 4px -2px 7px -1px rgba(69, 69, 69, 0.52);
  -moz-box-shadow: 4px -2px 7px -1px rgba(69, 69, 69, 0.52);
  padding: 1.2%;
  padding-top: 15px;
  padding-left: 10px;
  overflow-wrap: break-word;
  /* text-align: center; */
}

.blog-box-title {
  font-family: "Poppins", sans-serif;
  font-size: 145%;
  font-weight: 400;
  text-align: center;
  width: 100%;
  margin-top: 15px;
}

.blog-box-content {
  font-family: "Poppins", sans-serif;
  font-size: 100%;
}

/******************************/
/**********  WAVES  ***********/
/******************************/

.blog-box-container .wave {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 10px;
  background-image: url(../../../assets//wave.png);
  background-size: 1000px 100px;
}

.blog-box-container .wave.wave1 {
  animation: animate 30s linear infinite;
  z-index: 901;
  opacity: 1;
  animation-delay: 0s;
  bottom: 0;
}

.blog-box-container .wave.wave2 {
  animation: animate2 15s linear infinite;
  z-index: 900;
  opacity: 0.5;
  animation-delay: -5s;
  bottom: 0px;
}

.blog-box-container .wave.wave3 {
  animation: animate 30s linear infinite;
  z-index: 899;
  opacity: 0.2;
  animation-delay: -5s;
  bottom: 0px;
}

.blog-box-container .wave.wave4 {
  animation: animate2 15s linear infinite;
  z-index: 897;
  opacity: 0.7;
  animation-delay: -2s;
  bottom: 0;
}

@keyframes animate {
  0% {
    background-position-x: 0;
  }
  100% {
    background-position-x: 1000px;
  }
}

@keyframes animate2 {
  0% {
    background-position-x: 0;
  }
  100% {
    background-position-x: -1000px;
  }
}

@media only screen and (max-width: 1000px) {
  .blog-box-title {
    font-size: 150%;
  }

  .blog-box-container {
    height: 100px;
  }
}

@media only screen and (max-width: 850px) {
  .blog-box-title {
    font-size: 125%;
  }
}

@media only screen and (max-width: 650px) {
  .blog-box-container {
    width: 90%;
    margin: 3% 5%;
  }

  .blog-box-title {
    font-size: 180%;
  }
}

@media only screen and (max-width: 550px) {
  .blog-box-title {
    font-size: 160%;
  }
}

@media only screen and (max-width: 425px) {
  .blog-box-title {
    font-size: 130%;
  }
}
