.mobile-menu-logo-container {
  position: relative;
  width: 100%;
  height: 200px;
  background-color: white;
  margin-bottom: 20px;
}

.mobile-menu-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 100%;
}

.mobile-menu-icons.MuiSvgIcon-root {
  color: white;
  font-size: 23px;
  margin-right: 20px;
}

.buttonText {
  font-size: 150%;
  font-weight: 200;
  font-family: "Poppins", sans-serif;
  color: white;
  margin: 3% 0;
}

.menuBarContainer {
  position: absolute;
  bottom: 0;
  height: 100vh;
  width: 330px;
  background-color: white;
  left: 0;
  z-index: 2200;
}
