.header-bottom {
  width: 100%;
  /* background-color: #008fc9; */
  background-color: white;
  height: 65px;
  position: fixed;
  top: 0;
  z-index: 1000;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  border: 1px #008fc9 solid;
  border-top: 0;
}

.swimjo-logo-desk-container {
  height: 100%;
  width: 20%;
  position: absolute;
  left: 0;
  background-color: white;
}

.header-menu ul a {
  font-weight: 500;
  color: #008fc9;
  font-size: 16px;
  text-transform: capitalize;
  font-family: "Poppins", sans-serif;
  text-decoration: none;
}

.header-menu ul a:hover {
  color: #0031ac;
  cursor: pointer;
  font-size: 20px;
}

.header-menu ul {
  width: 60%;
  display: flex;
  justify-content: space-evenly;
  vertical-align: text-bottom;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.menuMobileDisplay {
  display: none;
}

.menuLogo {
}

.menuLogo.MuiSvgIcon-root {
  color: #008fc9;
  font-size: 50px;
  position: absolute;
  top: 10%;
  right: 40px;
}

.social-icons {
  position: absolute;
  top: 15px;
  right: 20px;
}

.swimjo-logo {
  position: absolute;
  top: 50%;
  left: 2%;
  transform: translate(0%, -50%);
  height: 200px;
  margin-left: 10px;
}

.social-icon-font.MuiSvgIcon-root {
  color: #008fc9;
  font-size: 35px;
  margin-left: 15px;
}

@media only screen and (max-width: 1050px) {
  .header-menu ul {
    width: 60%;
    left: 55%;
    transform: translate(-50%, -50%);
  }
}

@media only screen and (max-width: 850px) {
  .swimjo-logo {
    height: 150px;
  }
  .header-menu ul {
    width: 60%;
  }
  .header-menu ul a {
    font-size: 13px;
  }
}

@media only screen and (max-width: 800px) {
  .menuDeskDisplay {
    display: none;
  }

  .menuMobileDisplay {
    display: inline;
  }

  .social-icons {
    left: 20px;
  }
}
