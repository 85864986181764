.blog-page {
  padding-top: 80px;
  padding-bottom: 15px;
  background-color: #1fa2ff6b;

  margin: 0;
  clear: both;
  min-height: 100vh;
}

.blog-section {
  width: 90%;
  margin: 2% 5%;
  display: flex;
}

.MuiPagination-root {
  position: absolute;
  bottom: -35px;
  right: 50%;
  transform: translate(50%, -50%);
}

.pagination {
  text-align: center;
  position: relative;
  bottom: -30px;
  left: 50%;
  transform: translateX(-50%);
}

@media only screen and (max-width: 650px) {
  .blog-section {
    display: inline;
    margin: 2% 0;
  }
}
