.contact-section {
  position: relative;
  left: 0;
  bottom: 0;
  right: 0;
  /* height: 550px; */
  width: 100%;
  /**display: inline-flex;*/
  background-color: #008fc9;
  justify-content: space-evenly;
  clear: both;
  overflow: hidden;
  display: block;
}

.contact-all-container {
  display: flex;
  width: 90%;
  margin: 0 5%;
}

.contact-form-section {
  width: 45%;
  height: 85%;
  margin: 4% 0;
  z-index: 205;
}

.contact-info-section {
  padding: auto 0;
  width: 45%;
  height: 85%;
  margin: 4% 0;
}

.icon-contact-info {
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  margin: 0;
  margin-left: 20px;
  font-size: 160%;
  color: #008fc9;
  text-align: center;
  padding: 13px 0;
}

.contact-icon-font.MuiSvgIcon-root {
  font-size: 350%;
  /* color: #008fc9; */
  color: white;

  margin: 0;
}

.contact-icon-container {
  height: 102%;
  background-color: #f1592a;
  border-radius: 5px;
}

.icon-info-container {
  padding: 1%;
  text-align: center;
  display: flex;
  text-decoration: none;
  color: black;
  width: 90%;
  margin: 0 5%;
  margin-bottom: 5%;
  border-radius: 5px;
  background-color: white;
  box-shadow: 4px -2px 8px -1px rgba(255, 255, 255, 0.52);
  -webkit-box-shadow: 4px -2px 7px -1px rgba(69, 69, 69, 0.52);
  -moz-box-shadow: 4px -2px 7px -1px rgba(69, 69, 69, 0.52);
}

.by-convert-container {
  display: block;
  text-align: center;
  position: absolute;
  bottom: 1%;
  left: 50%;

  transform: translateX(-50%);
}

.by-convert-text {
  color: rgb(80, 80, 80);
  margin-bottom: 1.5px;
  margin-left: 4px;
  font-size: 11px;
  /**
  position: absolute;
  top: 50;
  transform: translate(-50%, 0%);
  */
}

.convert-logo-circle {
  background-color: white;
  width: 150px;
  height: 150px;
  border-radius: 50%;
  position: relative;
  bottom: -85px;
  left: 50%;
  transform: translateX(-50%);
}

.convert-positioning {
  position: absolute;
  top: 26%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.convert-logo {
  height: 15px;
  /* position: absolute;
  top: 50%;

  left: 50%;

  transform: translate(-50%, -50%); */
}

@media only screen and (max-width: 1000px) {
  .icon-contact-info {
    font-size: 115%;
  }

  .contanct-icon-font.MuiSvgIcon-root {
    font-size: 300%;
  }

  .contact-form-section {
    width: 50%;
  }
}

@media only screen and (max-width: 800px) {
  .contact-form-section {
    width: 48%;
    height: 85%;
    margin: 4% 1%;
  }
  .icon-contact-info {
    font-size: 90%;
  }

  .contanct-icon-font.MuiSvgIcon-root {
    font-size: 200%;
  }
}

@media only screen and (max-width: 650px) {
  .contact-all-container {
    display: grid;
  }

  .contact-form-section {
    height: 580px;
    z-index: 205;
  }

  .contact-section {
    height: 900px;
  }

  .contact-form-section {
    width: 90%;
    margin: 6% 5%;
  }

  .contact-info-section {
    width: 95%;
    margin: 6% 2.5%;
  }
  .icon-contact-info {
    font-size: 150%;
  }
  .contanct-icon-font.MuiSvgIcon-root {
    font-size: 350%;
  }
}

@media only screen and (max-width: 500px) {
  .by-convert-text {
    color: rgb(80, 80, 80);
    margin-bottom: 4px;
    margin-left: 4px;
    font-size: 10px;
  }

  /* .convert-logo {
    height: 10px;
  } */

  .icon-contact-info {
    font-size: 100%;
  }

  .contact-icon-font.MuiSvgIcon-root {
    font-size: 280%;
  }
}

@media only screen and (max-width: 400px) {
  .icon-contact-info {
    font-size: 80%;
  }
}

/************BUBBLES*******************/

.bubbles {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 10;
  overflow: hidden;
  top: 0;
  left: 0;
}
.bubble {
  position: absolute;
  bottom: -100px;
  width: 40px;
  height: 40px;
  background: #f1f1f1;
  border-radius: 50%;
  opacity: 0.5;
  animation: rise 10s infinite ease-in;
  z-index: 10;
}
.bubble:nth-child(1) {
  width: 40px;
  height: 40px;
  left: 10%;
  animation-duration: 8s;
}
.bubble:nth-child(2) {
  width: 20px;
  height: 20px;
  left: 20%;
  animation-duration: 5s;
  animation-delay: 1s;
}
.bubble:nth-child(3) {
  width: 50px;
  height: 50px;
  left: 35%;
  animation-duration: 7s;
  animation-delay: 2s;
}
.bubble:nth-child(4) {
  width: 80px;
  height: 80px;
  left: 50%;
  animation-duration: 11s;
  animation-delay: 0s;
}
.bubble:nth-child(5) {
  width: 35px;
  height: 35px;
  left: 55%;
  animation-duration: 6s;
  animation-delay: 1s;
}
.bubble:nth-child(6) {
  width: 45px;
  height: 45px;
  left: 65%;
  animation-duration: 8s;
  animation-delay: 3s;
}
.bubble:nth-child(7) {
  width: 90px;
  height: 90px;
  left: 70%;
  animation-duration: 12s;
  animation-delay: 2s;
}
.bubble:nth-child(8) {
  width: 25px;
  height: 25px;
  left: 80%;
  animation-duration: 6s;
  animation-delay: 2s;
}
.bubble:nth-child(9) {
  width: 15px;
  height: 15px;
  left: 70%;
  animation-duration: 5s;
  animation-delay: 1s;
}
.bubble:nth-child(10) {
  width: 90px;
  height: 90px;
  left: 25%;
  animation-duration: 10s;
  animation-delay: 4s;
}
@keyframes rise {
  0% {
    bottom: -100px;
    transform: translateX(0);
  }
  50% {
    transform: translate(100px);
  }
  100% {
    bottom: 1080px;
    transform: translateX(-200px);
  }
}
