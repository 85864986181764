@font-face {
  src: url(../../../UI/goth.ttf);
  font-family: goth;
}

.card {
  perspective: 150rem;
  -moz-perspective: 150rem;
  position: relative;
  width: 100%;
  height: 20rem;
  margin: 1%;
}
.card__side {
  height: 100%;
  transition: all 0.8s ease;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  backface-visibility: hidden;
  border-radius: 3px;
  overflow: hidden;
  box-shadow: 0 1.5rem 4rem rgba(0, 0, 0, 0.15);
}
.card__side--front {
  background-color: #e6e6e6;
}
.card__side--back {
  transform: rotateY(180deg);
}
.card__side--back-3 {
  background-image: linear-gradient(to right bottom, #2998ff, #008fc9);
}
.card:hover .card__side--front {
  transform: rotateY(-180deg);
}
.card:hover .card__side--back {
  transform: rotateY(0);
}
.card__picture {
  height: 100%;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
}
.card__picture--3 {
  background-image: linear-gradient(to right bottom, #008fc9, #272ef3);
}
.card__heading {
  font-size: 2.3rem;
  font-weight: 300;
  font-family: goth;
  text-transform: uppercase;
  text-align: center;
  color: black;
  position: absolute;
  margin: 0;
  top: 70%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  text-align: center;
}
.card__heading-span {
  font-family: goth;
  font-weight: 600;
  padding: 0.2rem 1.5rem;
  -webkit-box-decoration-break: clone;
  box-decoration-break: clone;
  margin: 0;
}
.card__heading-span--3 {
  margin-bottom: 5px;
  background-image: linear-gradient(
    to right bottom,
    rgba(41, 152, 255, 0.85),
    rgba(86, 67, 250, 0.85)
  );
}
.card--prod-box {
  text-transform: uppercase;
  text-align: center;
  padding: 0.2%;
  font-family: Lato;
  font-weight: 700;
  color: #008fc9;
  margin-top: 6%;
}

.team-desc {
  margin: 0;
  line-height: 2;
}

.card__price-value-1 {
  font-family: "Poppins", sans-serif;
  font-size: 0.65rem;
}

@media only screen and (max-width: 1100px) {
  .card {
    height: 18rem;
  }
}

@media only screen and (max-width: 1000px) {
  .card {
    height: 15.5rem;
  }
}
@media only screen and (max-width: 900px) {
  .card {
    height: 13rem;
  }
}
@media only screen and (max-width: 850px) {
  .card--prod-box {
    font-size: 2rem;
  }
}
@media only screen and (max-width: 850px) {
  .card--prod-box {
    font-size: 3rem;
  }

  .card {
    margin: 4% 17.5%;
    width: 65%;
    height: 37rem;
  }

  .card__heading {
    font-size: 3rem;
  }
}
.card--prod-box-span {
  color: #008fc9;
}
.card__cta {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90%;
  text-align: center;
}
.card__price-box {
  text-align: center;
  color: #fff;
  margin-bottom: 5rem;
}
.card__price-value {
  font-size: 1.5rem;
  font-weight: 100;
}

.image {
  object-fit: fill;
  height: 100%;
  width: 100%;
}

@media only screen and (max-width: 850px), only screen and (hover: none) {
  .image {
    object-fit: cover;
    height: 100%;
    width: 100%;
  }
}

@media only screen and (max-width: 650px) {
  .card {
    margin: 4% 12.5%;
    width: 85%;
    height: 34rem;
  }

  .card__heading {
    font-size: 3rem;
  }
  card__price-value-1 {
    font-size: 0.3rem;
  }
}

@media only screen and (max-width: 500px) {
  /* .carousell ul li {
     width: 320px !important; 
  } */

  .card {
    margin: 5% 10%;
    width: 100%;
    height: 20rem;
  }

  .card__heading {
    font-size: 2.3rem;
  }
}

@media only screen and (max-width: 450px) {
  /* .card {
    margin: 6% 7.5%;
    width: 90%;
    height: 25rem;
  } */

  .card__heading {
    font-size: 1.75rem;
  }
}

@media only screen and (max-width: 400px) {
  .card {
    margin: 6% 7.5%;
    /* width: 90%; */
    /* height: 25rem; */
  }

  .card__heading {
    font-size: 1.75rem;
  }
}

@media only screen and (max-width: 350px) {
  .card {
    margin: 7.5% 7.5%;
    /* width: 90%; */
    /* height: 21.5rem; */
  }

  .card__heading {
    font-size: 1.75rem;
  }
}
