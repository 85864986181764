.slide-container {
  width: 90%;
  height: 750px;
  margin: 0 5%;
}

.three-cards-container {
  justify-content: space-around;
  display: flex;
  width: 100%;
  margin: 0 auto;
}
