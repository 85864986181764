.article-page {
  padding: 0 6%;
  padding-top: 4%;
  clear: both;
  overflow: hidden;

  background-color: #1fa2ff6b;
}

.article-inner-page {
  clear: both;
  min-height: 80vh;
  width: 90%;
  margin: 5.5% 5%;
  background-color: white;
  box-shadow: 4px -2px 8px -1px rgba(255, 255, 255, 0.52);
  -webkit-box-shadow: 4px -2px 7px -1px rgba(69, 69, 69, 0.52);
  -moz-box-shadow: 4px -2px 7px -1px rgba(69, 69, 69, 0.52);
}

.article-title {
  margin: 0;
  font-family: "Poppins", sans-serif;
  font-size: 4rem;
  color: white;
  font-weight: 300;
  /* position: absolute;
  top: 20px; */
  margin-bottom: 50px;
}

.article-title-container {
  background-color: #008fc9;
  min-height: 60px;
  clear: both;
  width: 95%;
  padding: 9% 2.5%;
  position: relative;
  margin: 0;
  text-align: center;
  overflow-wrap: break-word;
  /* margin-bottom: 30px; */
}

.article-content-container {
  width: 95%;
  padding: 2.5%;
  background-color: #ededed2e;
}

.article-page-header {
  color: #454545;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 3.5rem;
  margin: 0;
}

.article-page-para {
  color: #454545;
  font-family: "Poppins", sans-serif;
  font-size: 1.5rem;
}

/******************************/
/**********  WAVES  ***********/
/******************************/

.article-title-container .wave {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 40px;
  background-image: url(../../assets/wave.png);
  background-size: 1000px 100px;
}

.article-title-container .wave.wave1 {
  animation: animate 30s linear infinite;
  z-index: 901;
  opacity: 1;
  animation-delay: 0s;
  bottom: 0;
}

.article-title-container .wave.wave2 {
  animation: animate2 15s linear infinite;
  z-index: 900;
  opacity: 0.5;
  animation-delay: -5s;
  bottom: 0px;
}

.article-title-container .wave.wave3 {
  animation: animate 30s linear infinite;
  z-index: 899;
  opacity: 0.2;
  animation-delay: -5s;
  bottom: 0px;
}

.article-title-container .wave.wave4 {
  animation: animate2 15s linear infinite;
  z-index: 897;
  opacity: 0.7;
  animation-delay: -2s;
  bottom: 0;
}

@keyframes animate {
  0% {
    background-position-x: 0;
  }
  100% {
    background-position-x: 1000px;
  }
}

@keyframes animate2 {
  0% {
    background-position-x: 0;
  }
  100% {
    background-position-x: -1000px;
  }
}

@media only screen and (max-width: 800px) {
  .article-inner-page {
    width: 95%;
    margin: 5.5% 2.5%;
  }
  .article-title {
    font-size: 4rem;
  }
  .article-page {
    padding-top: 8%;
  }
  .article-title-container {
    min-height: 40px;
  }
}

@media only screen and (max-width: 700px) {
  .article-title-container {
    padding: 17% 2.5%;
    /* min-height: 200px; */
  }
  .article-inner-page {
    width: 95%;
    margin: 5.5% 2.5%;
  }
  .article-title {
    font-size: 3.5rem;
  }
  .article-page {
    padding-top: 8%;
  }
}

@media (max-width: 550px) {
  .article-title-container {
    padding: 20% 2.5%;
  }
  .article-title {
    /* font-size: 2.4rem; */
    font-size: 1.4rem;
  }
  .article-page {
    padding-top: 14%;
  }

  .article-page-header {
    font-size: 3rem;
  }

  .article-page-para {
    font-size: 1.1rem;
  }
}

@media (max-width: 400px) {
  .article-inner-page {
    width: 98%;
    margin: 5.5% 1%;
  }
  .article-title-container {
    padding: 23% 2.5%;
  }
  .article-title {
    /* font-size: 2rem; */
    font-size: 1.6rem;
  }
  .article-page {
    padding-top: 18%;
  }

  .article-page-header {
    font-size: 2.6rem;
  }

  .article-page-para {
    font-size: 0.8rem;
  }
}
