.aboutus-section {
  height: 520px;
  width: 100%;
  background-color: #008fc9;
  clear: both;
  overflow: hidden;
  display: block;
  position: relative;
}

.section-title-about {
  text-align: center;
  vertical-align: middle;
  color: white;
  font-family: "Poppins", sans-serif;
  font-size: 60px;
  padding-top: 45px;
  margin: 0;
  font-weight: 400;
}

.about-flex {
  display: flex;
  width: 95%;
  margin: 0 2.5%;
  margin-top: 30px;
}

.about-text {
  color: white;
  font-family: "Poppins", sans-serif;
  font-size: 23px;
  font-weight: 100;
  text-align: left;
  margin: 0;
}

.about-text-container {
  width: 50%;
  text-align: center;
  margin-top: -4%;
}

.about-ill {
  width: 45%;
  position: relative;
  margin: 0 2.5%;
}

.about-ill-img {
  position: absolute;
  top: 35%;
  left: 55%;
  transform: translate(-50%, -50%);
  width: 450px;
}

@media only screen and (min-width: 1500px) {
  .aboutus-section {
    height: 650px;
  }
}

@media only screen and (min-width: 1400px) {
  .aboutus-section {
    height: 610px;
  }
}

@media only screen and (min-width: 1250px) {
  .aboutus-section {
    height: 570px;
  }
}

@media only screen and (max-width: 1100px) {
  .about-text {
    font-size: 23px;
  }
  .about-ill-img {
    width: 400px;
  }
}

@media only screen and (max-width: 1000px) {
  .about-text {
    font-size: 23px;
  }

  .aboutus-section {
    height: 525px;
  }
  .about-ill-img {
    position: absolute;
    top: 35%;
    left: 50%;
    width: 450px;
  }
}

@media only screen and (max-width: 850px) {
  .about-ill-img {
    width: 400px;
    top: 42%;
  }
}

@media only screen and (max-width: 800px) {
  .about-text {
    font-size: 18px;
  }

  .aboutus-section {
    height: 480px;
  }

  .about-text-container {
    margin-top: -5%;
  }

  .about-ill-img {
    position: absolute;
    top: 32%;
    left: 50%;
    width: 400px;
  }
}

@media only screen and (max-width: 700px) {
  .about-ill-img {
    position: absolute;
    top: 42%;
    left: 30%;
    width: 360px;
  }
}

@media only screen and (max-width: 600px) {
  .about-flex {
    display: block;
  }

  .about-text {
    font-size: 18px;
  }

  .aboutus-section {
    height: 770px;
  }

  .about-text-container {
    margin-top: -5%;
    margin: 0 5%;
    width: 90%;
  }
  .about-ill {
    width: 90%;
    position: relative;
    margin: 0 5%;
  }

  .about-ill-img {
    position: absolute;
    /* top: 42%; */
    top: 61%;
    left: 20%;
    width: 400px;
  }
}

/* @media only screen and (max-width: 500px) {
  .section-title-about {
    font-size: 55px;
  }
} */

@media only screen and (max-width: 500px) {
  .section-title-about {
    font-size: 40px;
  }

  .aboutus-section {
    height: 735px;
  }

  .about-ill-img {
    position: absolute;
    /* top: 42%; */
    top: 58%;
    left: -8%;
    width: 400px;
  }
}

@media only screen and (max-width: 350px) {
  .section-title-about {
    font-size: 40px;
  }

  .aboutus-section {
    height: 690px;
  }

  .about-ill-img {
    position: absolute;
    /* top: 42%; */
    top: 63%;
    left: -8%;
    width: 400px;
  }
}

/*****************BUBBLES*************************/

.bubbles {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 0;
  overflow: hidden;
  top: 0;
  left: 0;
}
.bubble {
  position: absolute;
  bottom: -100px;
  width: 40px;
  height: 40px;
  background: #f1f1f1;
  border-radius: 50%;
  opacity: 0.5;
  animation: rise 10s infinite ease-in;
}
.bubble:nth-child(1) {
  width: 40px;
  height: 40px;
  left: 10%;
  animation-duration: 8s;
}
.bubble:nth-child(2) {
  width: 20px;
  height: 20px;
  left: 20%;
  animation-duration: 5s;
  animation-delay: 1s;
}
.bubble:nth-child(3) {
  width: 50px;
  height: 50px;
  left: 35%;
  animation-duration: 7s;
  animation-delay: 2s;
}
.bubble:nth-child(4) {
  width: 80px;
  height: 80px;
  left: 50%;
  animation-duration: 11s;
  animation-delay: 0s;
}
.bubble:nth-child(5) {
  width: 35px;
  height: 35px;
  left: 55%;
  animation-duration: 6s;
  animation-delay: 1s;
}
.bubble:nth-child(6) {
  width: 45px;
  height: 45px;
  left: 65%;
  animation-duration: 8s;
  animation-delay: 3s;
}
.bubble:nth-child(7) {
  width: 90px;
  height: 90px;
  left: 70%;
  animation-duration: 12s;
  animation-delay: 2s;
}
.bubble:nth-child(8) {
  width: 25px;
  height: 25px;
  left: 80%;
  animation-duration: 6s;
  animation-delay: 2s;
}
.bubble:nth-child(9) {
  width: 15px;
  height: 15px;
  left: 70%;
  animation-duration: 5s;
  animation-delay: 1s;
}
.bubble:nth-child(10) {
  width: 90px;
  height: 90px;
  left: 25%;
  animation-duration: 10s;
  animation-delay: 4s;
}
@keyframes rise {
  0% {
    bottom: -100px;
    transform: translateX(0);
  }
  50% {
    transform: translate(100px);
  }
  100% {
    bottom: 1080px;
    transform: translateX(-200px);
  }
}
