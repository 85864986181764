#card {
  /**width: 105%;*/
  width: 85%;
  height: 360px;
  margin: 3px 10px;
  border-radius: 8px;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.2), 0px 2px 6px rgba(0, 0, 0, 0.4);
  overflow: hidden;
}

.service-card-container {
  /**
  width: 400px;
  margin: 3px 200px;
  */
}

#blur {
  -webkit-filter: blur(1px);
  -moz-filter: blur(1px);
  filter: grayscale(10%);
  /**
  background: url(../../../assets/pool.jpg) no-repeat center center fixed;
  background-color: #008fc9;
  -webkit-filter: blur(8px);
  -moz-filter: blur(8px);
  filter: blur(8px);
  */
  /* width: 460px; */
  height: 440px;
  position: relative;
  top: -65px;
  /* left: -95px; */
  border-radius: 8px;
}

#color {
  width: 530px;
  height: 330px;
}

#profile {
  width: 100%;
  height: 230px;
  position: relative;
  top: -260px;
  border-radius: 8px;
}
/**
img {
  width: 100px;
  height: 100px;
  border-radius: 100px;
  position: relative;
  top: 32px;
  display: block;
  margin: 0 auto;
  -webkit-filter: sepia(0.25);
  -moz-filter: sepia(0.25);
  filter: sepia(0.25);
}
*/
h1 {
  color: rgba(38, 50, 56, 1);
  font-family: "Roboto Condensed", sans-serif;
  font-size: 36px;
  font-weight: 700;
  text-align: center;
  position: relative;
  top: 48px;
}

.service-card-text {
  color: rgba(38, 50, 56, 0.87);
  font-family: "Roboto Condensed", sans-serif;
  font-size: 16px;
  font-weight: 300;
  text-align: center;
  position: relative;
  margin: 0 40px;
}

.service-card-text-container {
  width: 50%;
  margin: 0 25%;
}

.service-card-text-front {
  position: relative;
  /**color: #008fc9;*/
  color: white;
  font-family: "Poppins", sans-serif;
  font-size: 30px;
  font-weight: 900;
  text-align: center;
  margin: 0 5%;
  margin-top: 26px;
}

.info {
  text-align: center;
  margin: 0 auto;
  position: relative;
  top: 30px;
  width: 32px;
  height: 32px;
  border-radius: 100px;
  background-position: center center;
  background-size: cover;
  transition: all 0.2s ease;
  z-index: 0;
}
/***** active/info ****/
.info.active {
  width: 460px;
  height: 550px;
  position: relative;
  top: 100%;
  left: 50%;
  transform: translate(-50%, -100%);
  padding: 10px 55px;
  /**background: rgb(0, 101, 151);*/
  background-color: #008fc9;
  transition: width 0.1s ease, height 0.2s ease, top 0.2s ease,
    z-index 0.2s ease;
  z-index: 999;
}

.info i.block {
  color: rgba(255, 255, 255, 0.6);
  display: block;
}

.info .fa-info {
  cursor: pointer;
  visibility: visible;
  opacity: 1;
  transition: visibility 0s, opacity 0.4s ease;
  font-style: normal;
  font-size: 15px;
  font-weight: 300;
  font-family: "Poppins", sans-serif;
  text-transform: lowercase;
}

.service-icon.MuiSvgIcon-root {
  font-size: 50px;
  position: absolute;
  top: 250%;
  left: 50%;
  transform: translate(-50%, -50%);
}
/***** active/info ****/
.info.active .fa-info {
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s, opacity 0.4s ease;
}

.info .fa-angle-down {
  cursor: pointer;
  visibility: hidden;
}

/***** active/info ****/
.info.active .fa-angle-down {
  position: relative;
  margin: 0 auto;
  width: 24px;
  height: 24px;
  padding: 4px;
  visibility: visible;
  position: relative;
  top: 128px;
  animation-name: visible;
  animation-duration: 0.3s;
}

@keyframes visible {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.info p {
  color: rgba(255, 255, 255, 1);
  font-family: "Roboto Condensed", sans-serif;
  font-size: 12px;
  line-height: 110%;
  font-weight: 400;
  text-align: center;
  padding: 5px 75px;
  position: relative;
  top: 144px;
  visibility: hidden;
}

.info ul {
  color: rgba(255, 255, 255, 1);
  font-family: "Roboto Condensed", sans-serif;
  font-size: 12px;
  line-height: 110%;
  font-weight: 400;
  text-align: center;
  padding: 5px 55px;
  position: relative;
  top: 144px;
}

.info.active ul li {
  list-style-type: circle;
}

.info.active p {
  visibility: visible;
  animation-name: entrance-first;
  animation-duration: 0.4s;
}

.info .link {
  visibility: hidden;
  margin: 24px 24px 0px 24px;
  padding: 16px 4px 4px 4px;
  cursor: pointer;
  position: relative;
  top: 144px;
}

/***** active/info ****/
.info.active .link {
  visibility: visible;
  animation-name: entrance-second;
  animation-duration: 0.5s;
}

@keyframes entrance-first {
  0% {
    top: 200px;
  }
  100% {
    top: 144px;
  }
}

@keyframes entrance-second {
  0% {
    top: 200px;
  }
  100% {
    top: 144px;
  }
}

@media only screen and (max-width: 700px) {
  .info p {
    font-size: 11px;
  }

  .info.active {
    padding: 20px 50px;
  }

  /* #card {
    width: 90%;
    height: 360px;
  }

  #blur {
    left: -55px;
  }  */
}

@media only screen and (max-width: 600px) {
  .info p {
    font-size: 12px;
    padding: 20px 100px;
  }
  .service-card-text-front {
    font-size: 25px;
  }

  .info.active {
    padding: 20px 40px;
  }
  /* 
  #card {
    width: 90%;
    height: 320px;
  }

  #blur {
    left: -110px;
  } */
}

@media only screen and (max-width: 500px) {
  .info.active {
    padding: 20px 40px;
  }
  .info p {
    font-size: 12px;
    padding: 20px 85px;
  }

  /* #blur {
    left: -125px;
  } */
}

@media only screen and (max-width: 450px) {
  .service-card-text-front {
    font-size: 22px;
  }

  .info.active {
    padding: 20px 40px;
  }

  .info p {
    font-size: 11px;
    padding: 20px 100px;
  }

  #card {
    width: 100%;
    margin: 0;
  }

  /* #blur {
    left: -130px;
  } */
}
