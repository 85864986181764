.ourteam-section {
  position: relative;
  height: 620px;
  width: 100%;
  background-color: #d8efff;
  display: block;
  overflow: hidden;
}

.ourteam-slide {
  height: 330px;
  width: 98%;
  /**
  box-shadow: 0 1.5rem 4rem rgba(0, 0, 0, 0.15);0 4px 2px 2px
  */
  box-shadow: 0 10px 4px 4px #e8e8e8;

  margin: 0 auto;
}

.team-img {
  width: 100%;
  height: 100%;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
/**
.ourteam-slide:hover {
  height: 340px;
  width: 92%;
}


.ourteam-slide:hover .ourteam-slide-bottom {
  background-color: #008fc9;
}

.ourteam-slide:hover .team-name-style {
  color: white;
}
*/

.three-cards-container {
  justify-content: space-around;
  display: flex;
  width: 100%;
  margin: 0 auto;
}

.two-cards-container {
  justify-content: space-around;
  display: flex;
  width: 66%;
  margin: 0 auto;
}

.carousel-container-with-scrollbar {
  padding-top: 45px;
  width: 90%;
  margin: 0 5%;
  /**
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  */
}

.name-style {
  text-align: center;
  vertical-align: middle;
  color: white;
  font-family: "Poppins", sans-serif;
  font-size: 24px;
  font-weight: 300;
  margin: 0;
}

.section-title-team {
  text-align: center;
  vertical-align: middle;
  color: #008fc9;
  font-family: "Poppins", sans-serif;
  font-size: 65px;
  padding-top: 30px;
  margin: 0;
  font-weight: 400;
}

.ourteam-slide-top {
  width: 100%;
  height: 80%;
  border-radius: 3px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.ourteam-slide-bottom {
  background-color: #008fc9;
  padding-top: 15px;
  width: 100%;
  height: 20%;

  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

@media only screen and (max-width: 850px) {
  .three-cards-container {
    display: inline;
  }

  .two-cards-container {
    display: inline;
  }

  /* .ourteam-section {
    height: 3000px;
  } */
}

@media only screen and (max-width: 600px) {
  /* .ourteam-section {
    height: 650px;
  } */
  .section-title-team {
    font-size: 50px;
  }

  .name-style {
    font-size: 20px;
  }
}

@media only screen and (max-width: 500px) {
  .section-title-team {
    font-size: 55px;
  }

  /* .ourteam-section {
    height: 3000px;
  } */
}

@media only screen and (max-width: 450px) {
  .section-title-team {
    font-size: 55px;
  }

  /* .ourteam-section {
    height: 2500px;
  } */
}

@media only screen and (max-width: 400px) {
  .section-title-team {
    font-size: 55px;
  }

  /* .ourteam-section {
    height: 2350px;
  } */
}

@media only screen and (max-width: 400px) {
  .section-title-team {
    font-size: 55px;
  }

  /* .ourteam-section {
    height: 2250px;
  } */
}
