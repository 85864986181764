.gallery-section {
  height: 1000px;
  width: 100%;
  margin: 0;
  background-color: #008fc9;
  position: relative;
  clear: both;
  overflow: hidden;
  display: block;
}

.ReactGridGallery_tile {
  background-color: #008fc9;
}

.section-title-gallery {
  text-align: center;
  vertical-align: middle;
  color: white;
  font-family: "Poppins", sans-serif;
  font-size: 65px;
  padding-top: 30px;
  margin: 0;
  font-weight: 400;
}

.img-gallery {
  /**height: 350px;
  
  margin: 0 5%;
  margin-top: 40px;
  width: 50%;
  */
}

.gallery-flex {
  display: flex;
  width: 95%;
  margin: 0 2.5%;
  margin-top: 3%;
}

.gallery-ImgGallery {
  width: 50%;
  margin: auto;
}

.gallery-text {
  vertical-align: middle;
  width: 43%;
}

.gallery-text-par {
  color: white;
  font-family: "Poppins", sans-serif;
  font-size: 23px;
  font-weight: 100;
  text-align: left;
  margin-top: 30px;
  margin-bottom: 3%;
  margin-left: 3%;
  vertical-align: middle;
}

.image-gallery-right-nav {
  transform: translate(50%, -100%) !important;
}

.image-gallery-left-nav {
  transform: translate(-50%, -100%) !important;
}

@media only screen and (min-width: 1500px) {
  .gallery-section {
    height: 1000px;
  }
}

@media only screen and (min-width: 1400px) {
  .gallery-section {
    height: 900px;
  }
}

@media only screen and (min-width: 1250px) {
  .gallery-section {
    height: 840px;
  }
}

@media only screen and (min-width: 1001px) {
  .gallery-section {
    height: 750px;
  }
}

@media only screen and (max-width: 1000px) {
  .gallery-section {
    height: 630px;
  }

  .gallery-text-par {
    font-size: 19px;
  }
}

@media only screen and (max-width: 800px) {
  .gallery-section {
    height: 525px;
  }

  .gallery-text-par {
    font-size: 14.5px;
  }
}

@media only screen and (max-width: 600px) {
  .gallery-ImgGallery {
    margin-top: 50px !important;
  }

  .gallery-flex {
    display: block;
  }

  .gallery-section {
    height: 925px;
  }

  .gallery-text-par {
    margin-top: 1%;
    font-size: 20px;
  }

  .gallery-ImgGallery {
    margin-top: 50px !important;
  }

  .gallery-text {
    width: 90%;
    margin: 0 5%;
  }
}

@media only screen and (max-width: 500px) {
  .gallery-section {
    height: 920px;
  }
  .gallery-text-par {
    font-size: 18px;
  }

  .section-title-gallery {
    font-size: 55px;
  }
}

@media only screen and (max-width: 400px) {
  .gallery-section {
    height: 920px;
  }
  .gallery-text-par {
    font-size: 16px;
  }

  .gallery-ImgGallery {
    width: 95%;
    margin: 0 2.5%;
  }

  .section-title-gallery {
    font-size: 45px;
  }
}

/********************BUBBLES*************/

.bubbles {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 0;
  overflow: hidden;
  top: 0;
  left: 0;
}
.bubble {
  position: absolute;
  bottom: -100px;
  width: 40px;
  height: 40px;
  background: #f1f1f1;
  border-radius: 50%;
  opacity: 0.5;
  animation: rise 10s infinite ease-in;
}
.bubble:nth-child(1) {
  width: 40px;
  height: 40px;
  left: 10%;
  animation-duration: 8s;
}
.bubble:nth-child(2) {
  width: 20px;
  height: 20px;
  left: 20%;
  animation-duration: 5s;
  animation-delay: 1s;
}
.bubble:nth-child(3) {
  width: 50px;
  height: 50px;
  left: 35%;
  animation-duration: 7s;
  animation-delay: 2s;
}
.bubble:nth-child(4) {
  width: 80px;
  height: 80px;
  left: 50%;
  animation-duration: 11s;
  animation-delay: 0s;
}
.bubble:nth-child(5) {
  width: 35px;
  height: 35px;
  left: 55%;
  animation-duration: 6s;
  animation-delay: 1s;
}
.bubble:nth-child(6) {
  width: 45px;
  height: 45px;
  left: 65%;
  animation-duration: 8s;
  animation-delay: 3s;
}
.bubble:nth-child(7) {
  width: 90px;
  height: 90px;
  left: 70%;
  animation-duration: 12s;
  animation-delay: 2s;
}
.bubble:nth-child(8) {
  width: 25px;
  height: 25px;
  left: 80%;
  animation-duration: 6s;
  animation-delay: 2s;
}
.bubble:nth-child(9) {
  width: 15px;
  height: 15px;
  left: 70%;
  animation-duration: 5s;
  animation-delay: 1s;
}
.bubble:nth-child(10) {
  width: 90px;
  height: 90px;
  left: 25%;
  animation-duration: 10s;
  animation-delay: 4s;
}
@keyframes rise {
  0% {
    bottom: -100px;
    transform: translateX(0);
  }
  50% {
    transform: translate(100px);
  }
  100% {
    bottom: 1080px;
    transform: translateX(-200px);
  }
}
