.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.whatsappBtn {
  position: fixed;
  bottom: 46px;
  right: 80px;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  /* background-color: white; */
  color: white;
  z-index: 1000;
  animation: 1s ease-out slideInFromLeft;
}

.whatsappIcon {
  animation: bouncingBtn infinite 5s forwards;
  margin-left: 45px;
  margin-top: 10px;
  height: 50px;
  /* margin-left: 45px; */
  /* animation-delay: 10s; */
}

.whatsappBox {
  margin-right: 45px;
  text-align: center;
  width: 135px;
  height: 35;
  padding: 3px 3px;
  background-color: white;
  border-radius: 5px;
  color: #282c34;
  font-size: 17px;
  font-weight: 500;
  z-index: 1001;
}

@keyframes slideInFromLeft {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes bouncingBtn {
  0% {
    transform: scale(1);
  }
  5% {
    transform: scale(1.2);
  }
  95% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.2);
  }
}
